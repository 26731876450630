import { HttpClient, HttpResponse } from '@wix/yoshi-flow-editor';
import { queryQuestionEntries } from '@wix/ambassador-faq-question-entry-v2-question-entry/http';
import { listCategories } from '@wix/ambassador-faq-category-v2-category/http';
import type { ListCategoriesResponse } from '@wix/ambassador-faq-category-v2-category/types';
import { SortOrder } from '@wix/ambassador-faq-question-entry-v2-question-entry/types';

export class FAQService {
  faqService: HttpClient;
  constructor(instance: string = '', useBaseURL = false) {
    this.faqService = new HttpClient({
      baseURL: useBaseURL ? 'https://editor.wixapps.net/faq' : undefined,
      headers: {
        Authorization: instance,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });
  }

  getListCategories = async () => {
    const response: HttpResponse<ListCategoriesResponse> =
      await this.faqService.request(listCategories({}));
    return response.data;
  };

  getListQuestions = async ({
    categoryId,
  }: {
    categoryId?: string | null | undefined | (string | null | undefined)[];
  }) => {
    const response = await this.faqService.request(
      queryQuestionEntries({
        query: {
          filter: { categoryId },
          sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
        },
      }),
    );
    return response.data;
  };

  getQuestion = async ({
    id,
  }: {
    id?: string | null | undefined | (string | null | undefined)[];
  }) => {
    const response = await this.faqService.request(
      queryQuestionEntries({
        query: {
          filter: { id },
          sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
        },
      }),
    );
    return response.data;
  };

  getSearchTerm = async ({ term }: { term: string }) => {
    term = '%' + `${term}`.trim().replaceAll(' ', '%');
    const response = await this.faqService.request(
      queryQuestionEntries({
        query: {
          filter: {
            $or: [
              {
                draftjs: {
                  $startsWith: term,
                },
              },
              {
                question: {
                  $startsWith: term,
                },
              },
            ],
          },
        },
      }),
    );
    return response.data;
  };
}
