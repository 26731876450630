import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';
import { CategoriesPosition, Structure } from '../../enums';

export type DisplayContent = 'splitIntoCategories' | 'oneListOfQuestions';
export type Layout = 'collapse' | 'stripes' | 'basic';
export type TextAlignment = 'left' | 'right';
export type HeightApp = 'adjust' | 'fixed';
export type Spaces = 'loose' | 'tight';
export enum OpenQuestionIcon {
  ARROW = 'arrow',
  PLUS = 'plus',
  ARROWUP = 'arrowUp',
  MINUS = 'minus',
  PLUSCIRCLE = 'plusCircle',
  ARROWUPRIGHT = 'arrowUpRight',
  EYEICON = 'eyeIcon',
}
export type DisplayCategories = 'above' | 'top';
export type TitleAlignment = 'center' | 'left' | 'right';
export enum LayoutType {
  Collapse = 'collapse',
  Stripes = 'stripes',
  Basic = 'basic',
}

export type Spacing = {
  gap: number;
  vertical: number;
  horizontal: number;
  betweencols: number;
};

export enum ItemAppearance {
  BOX = 'box',
  LINE = 'line',
  NONE = 'none',
}
export type SocialData = {
  show: boolean;
  facebook: boolean;
  twitter: boolean;
  linkedin: boolean;
  copyQuestionLink: boolean;
};

export type ISettingsParams = {
  openFirstAnswer: boolean;
  enableScrollAnimation: boolean;
  textAlignment: TextAlignment;
  heightApp: HeightApp;
  widgetUrl: string;
  shortenUrl: string;
  spaces: Spaces;
  manageCategories: IManageCategories;
  layout: LayoutType;
  displayContent: DisplayContent;
  seo: {
    show: boolean;
  };
  title: {
    text?: string;
    show: boolean;
    alignment: TitleAlignment;
  };
  searchBar: {
    text: string;
    show: boolean;
  };
  social: SocialData;
  openOneAnswer: boolean;
  openQuestionIcon: OpenQuestionIcon;
  dividerCollapse: number;
  displayCategories: DisplayCategories;
  mobile: {
    titleFontSize: number;
    categoryFontSize: number;
    questionsNumberFontSize: number;
    questionsFontSize: number;
    answersFontSize: number;
  };
  previewFirstAnswer: boolean;
  categoriesPosition: CategoriesPosition;
  structure: Structure;
  spacing: {
    gap: number;
    vertical: number;
    horizontal: number;
    betweencols: number;
  };
  openQuestionIconURL: string;
  itemAppearance: ItemAppearance;
};

export const getSpacingDefaults = ({ isMobile }: { isMobile: boolean }) => ({
  gap: 12,
  vertical: isMobile ? 20 : 32,
  horizontal: isMobile ? 20 : 32,
  betweencols: 12,
});

export default createSettingsParams<ISettingsParams>({
  layout: {
    getDefaultValue: () => LayoutType.Collapse,
  },
  openFirstAnswer: {
    getDefaultValue: () => true,
  },
  enableScrollAnimation: {
    getDefaultValue: () => true,
  },
  textAlignment: {
    getDefaultValue: () => 'left',
  },
  heightApp: {
    getDefaultValue: () => 'adjust',
  },
  widgetUrl: {
    getDefaultValue: () => '',
  },
  shortenUrl: {
    getDefaultValue: () => '',
  },
  spaces: {
    getDefaultValue: () => 'loose',
  },
  manageCategories: {
    inheritFromAppScope: true,
    getDefaultValue: () => ({
      showAll: true,
      categories: [],
    }),
  },
  displayContent: {
    getDefaultValue: () => 'splitIntoCategories',
  },
  seo: {
    getDefaultValue: () => ({ show: true }),
  },
  title: {
    getDefaultValue: ({ t }) => ({
      text: t('display.tab.title.demo'),
      show: true,
      alignment: 'left',
    }),
  },
  searchBar: {
    getDefaultValue: ({ t }) => ({
      text: t('display.tab.search.bar.demo'),
      show: true,
    }),
  },
  social: {
    getDefaultValue: () => ({
      show: true,
      facebook: true,
      twitter: true,
      linkedin: true,
      copyQuestionLink: true,
    }),
  },
  openOneAnswer: {
    getDefaultValue: () => true,
  },
  openQuestionIcon: {
    inheritFromAppScope: true,
    getDefaultValue: ({ isMobile }: { isMobile: boolean }) =>
      isMobile ? OpenQuestionIcon.ARROW : OpenQuestionIcon.EYEICON,
  },
  openQuestionIconURL: {
    getDefaultValue: () => '',
  },
  dividerCollapse: {
    getDefaultValue: () => 1,
  },
  displayCategories: {
    getDefaultValue: () => 'top',
  },
  mobile: {
    getDefaultValue: () => ({
      titleFontSize: 20,
      categoryFontSize: 16,
      questionsNumberFontSize: 50,
      questionsFontSize: 16,
      answersFontSize: 14,
    }),
  },
  previewFirstAnswer: {
    getDefaultValue: () => false,
  },
  categoriesPosition: {
    inheritDesktop: false,
    getDefaultValue: () => CategoriesPosition.TOP,
  },
  structure: {
    inheritDesktop: false,
    getDefaultValue: ({ isMobile }: { isMobile: boolean }) =>
      isMobile ? Structure.ONE_COLUMN : Structure.TWO_COLUMNS,
  },

  spacing: {
    type: SettingsParamType.Object,
    key: 'spacing',
    getDefaultValue: getSpacingDefaults,
  },
  itemAppearance: {
    getDefaultValue: ({ isMobile }: { isMobile: boolean }) =>
      isMobile ? ItemAppearance.LINE : ItemAppearance.BOX,
  },
});

type IManageCategories = { showAll: boolean; categories: string[] };
